@charset "utf-8";
/* CSS Document */

@media only screen and (min-width: 500px) {
  body {
    margin: auto;
    width: 85%
  }
}

@media only screen and (min-width: 768px) {
  body {
    margin: auto;
    width: 64%
  }
}

@media only screen and (min-width: 900px) {
  body {
    margin: auto;

    width: 55%
  }

  span.desktop-break::before {
    content: "\A";
    white-space: pre;
  }
}
