@media only screen and (width >= 500px) {
  body {
    width: 85%;
    margin: auto;
  }
}

@media only screen and (width >= 768px) {
  body {
    width: 64%;
    margin: auto;
  }
}

@media only screen and (width >= 900px) {
  body {
    width: 55%;
    margin: auto;
  }

  span.desktop-break:before {
    content: "\a ";
    white-space: pre;
  }
}

/*# sourceMappingURL=responsive.css.map */
